<template>
    <div class="not-found">
        <h1>404</h1>
        <p>Страница не найдена</p>
        <router-link to="/Results">Вернуться на главную</router-link>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
};
</script>

<style scoped>
.not-found {
    text-align: center;
    margin-top: 50px;
}
</style>